<template>
  <div>
    <exception type="404"></exception>
  </div>
</template>

<script>
import exception from "@/components/exception/ExceptionPage";

export default {
  components: { exception },
};
</script>
